import { styled } from "@mui/material"
import { Theme } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import React, {ReactNode} from "react"

type IconProps = {
    size?: "small" | "normal" | "large" | "custom"
    fill?: string
    customSize?: {
        width: string
        height: string
    }
    icon?: ReactNode
    children?: ReactNode
    sx?: SxProps<Theme>
}

export const Icon = (props: IconProps) => {
    if (!props.icon && !props.children) {
        return null
    }

    const sizes = {
        small: {
            width: "16px",
            height: "16px"
        },
        normal: {
            width: "32px",
            height: "32px"
        },
        large: {
            width: "64px",
            height: "64px"
        },
        custom: props.customSize
    }

    const IconWrapper = styled("div")((theme) => ({
        "& .cls-1": {
            fill: props.fill || "black"
        },
        fontSize: "inherit !important",
        ...sizes[props.size || "normal"]
    }))

    return (
        <IconWrapper sx={props.sx}>{props.icon || props.children}</IconWrapper>
    )
}

import React from 'react';

import './App.css';
import {RouterProvider} from "react-router-dom";
import {router} from "./app/router";
import i18n from "i18next";
import {ThemeProvider} from "@mui/material";
import {loadTheme} from "./themes/themes";

function App() {

    i18n.changeLanguage("de").then(() => console.log("language is de"))
    // i18n.changeLanguage("en")
    const theme = loadTheme("TSV")
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <RouterProvider router={router}/>
            </div>
        </ThemeProvider>
    );
}

export default App;

import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const Redirect: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const { keycloak } = useKeycloak();

    console.log("is authed", keycloak.authenticated)

    return (
        <Route
            {...rest}
            render = {(props) =>
                keycloak.authenticated
                    ? (<Component {...props} />)
                    : (keycloak.login({ redirectUri: window.location.href }))
            }
        />
    );
};

const Hidden = ({ children }) => {
    const { keycloak } = useKeycloak();

//    console.log("is authed", keycloak.authenticated)

    const isLoggedIn = keycloak.authenticated;

    return isLoggedIn ? children : null;
};

const Admin = ({ children }) => {
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    const isAdmin: boolean = isLoggedIn && keycloak.idTokenParsed?.preferred_username.toLowerCase() === "stefan" ||
        keycloak.idTokenParsed?.preferred_username.toLowerCase() === "timo" ||
        keycloak.idTokenParsed?.preferred_username.toLowerCase() === "moritz"

//    return isAdmin ? children : null;
    return children
};


export default { Redirect, Hidden, Admin };
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationsEN from "./locales/translations_en.json"
import translationsDE from "./locales/translations_de.json"

const resources = {
    "en": {translations: translationsEN},
    "de": {translations: translationsDE}
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'de',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n
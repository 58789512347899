import React from "react"
import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    useTheme
} from "@mui/material"
import { useTranslation } from "react-i18next"

export type ButtonProps = MuiButtonProps & {
    text?: string
    size?: "small" | "normal" | "large"
    primary?: boolean
    secondary?: boolean
}
export const Button = (props: ButtonProps) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const { primary, secondary, text, size, ...delegatedProps } = props
    const sizes = {
        small: {
            minHeight: "28px",
            fontSize: "12px"
        },
        normal: {
            minHeight: "36px",
            fontSize: "14px"
        },
        large: {
            minHeight: "56px",
            fontSize: "16px"
        }
    }
    const primaryStyle = {
        borderColor: `${theme.palette.primary.main} !important`,
        backgroundColor:
            props.variant === "contained" ? theme.palette.primary.main : "none",
        color:
            props.variant === "contained"
                ? theme.palette.common.white
                : theme.palette.primary.main,
        "& .cls-1": {
            fill:
                props.variant === "contained"
                    ? theme.palette.common.white
                    : theme.palette.primary.main
        }
    }
    const secondaryStyle = {
        borderColor: `${theme.general.grayLight} !important`,
        backgroundColor:
            props.variant === "contained" ? theme.general.grayLight : "none",
        color: theme.general.grayDark,
        "& .cls-1": {
            fill: theme.general.grayDark
        }
    }

    const iconButtonStyles = () => {
        let iconButtonStyle = {}
        if (!props.text && !props.children) {
            if (props.startIcon) {
                iconButtonStyle = {
                    ...iconButtonStyle,
                    ...{
                        "& .MuiButton-startIcon": {
                            marginLeft: 0,
                            marginRight: 0
                        }
                    }
                }
            }
            if (props.endIcon) {
                iconButtonStyle = {
                    ...iconButtonStyle,
                    ...{
                        "& .MuiButton-endIcon": {
                            marginLeft: 0,
                            marginRight: 0
                        }
                    }
                }
            }
        }
        return iconButtonStyle
    }

    return (
        <MuiButton
            variant="outlined"
            color={secondary ? "secondary" : "primary"}
            {...delegatedProps}
            sx={{
                whiteSpace: "nowrap",
                borderWidth: "2px !important",
                textTransform: "unset",
                boxShadow: "none !important",
                ...sizes[size || "normal"],
                ...props.sx,
                ...(primary ? primaryStyle : secondaryStyle),
                ...iconButtonStyles()
            }}
        >
            {text ? t(text) : props.children}
        </MuiButton>
    )
}

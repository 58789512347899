import {ListItemIcon, ListItemText, MenuItem, MenuItemProps, styled, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {Icon} from "../../components/Icon";
import {useTranslation} from "react-i18next";

type Selectable = {
    selected: boolean
}

type NavigationListItem = Selectable & {
    icon: ReactNode
}

const StyledMenuItem = styled(MenuItem)<MenuItemProps & Selectable>(
    ({ theme, selected }) => ({
        "&.Mui-selected": {
            color: "black"
        },
        fontSize: "14px",
        color: "grey",
        boxShadow: `inset ${selected ? "3px" : "0"} 0 0 0 ${
            "red"
        }`,
        paddingLeft: "2.5rem",
        marginBottom: "0.75rem"
    })
)

const NavigationListItemIcon = ({ selected,  icon }: NavigationListItem) => {
    return (
        <ListItemIcon>
            <Icon fill={selected ? "black" : "grey"}>
                {icon}
            </Icon>
        </ListItemIcon>
    )
}

type Props = {
    label: string
    linkTo: string
    icon: React.ReactNode
    selected?: boolean
    select(): void
}

export const NavigationItem = ({label, icon, select, selected = false}: Props) => {
    const { t } = useTranslation()
    return (
        <StyledMenuItem selected={selected} onClick={select}>
            <NavigationListItemIcon selected={selected} icon={icon} />
            <ListItemText>
                <Typography noWrap>{t(label)}</Typography>
            </ListItemText>
        </StyledMenuItem>
    )
}
import React, { useState } from 'react';
import PrivateRoute from "../../helpers/PrivateRoute";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { Box, Stack, TextField, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { Add, Delete, KeyboardArrowRight } from "@mui/icons-material";
import { Button } from "../../common/Button";
import { useGetPersonsQuery, useAddPersonMutation, useDeletePersonMutation, useSetCreditMutation } from "../../services/personsAPI";

type SetCreditOptions = {
    affectedPerson: string,
    triggeredFromUser: string,
    oldCredit: number,
    newCredit: number
}

const AdminPage = () => {
    const {keycloak} = useKeycloak();
    const { t } = useTranslation()
    const [personNameToDelete, setPersonNameToDelete] = useState("")
    const [affectedPerson, setAffectedPerson] = useState("")
    const [oldCredit, setOldCredit] = useState()
    const [newCredit, setNewCredit] = useState(0)
    const [newName, setNewName] = useState()
    const [newLastName, setNewLastName] = useState()

    const [deletePerson] = useDeletePersonMutation()
    const [setCredit] = useSetCreditMutation()
    const [addPerson] = useAddPersonMutation()

    let {data} = useGetPersonsQuery();

    const setCreditAction = () => {
        const creditOptions: SetCreditOptions = {
            affectedPerson: affectedPerson,
            triggeredFromUser: keycloak.idTokenParsed?.preferred_username,
            oldCredit: oldCredit,
            newCredit: newCredit
        };
        setCredit(creditOptions)
    }
    const addPersonAction = () => {
        const person: Person = {
            id: "someid",
            name: newName,
            lastName: newLastName,
            credit: newCredit
        };
        addPerson(person)
    }

    if (!keycloak.authenticated) {
        return <div>
            <h1>{t("translations:admin:site_restricted")}</h1>
            <button onClick={keycloak.login}>{t("translations:login")}</button>
        </div>
    }

    let names:[string] = [];

    if (data) {
        names = data.map( person => {
            return person.name
        })
    }

    return (
        <PrivateRoute.Admin>
            <div>
                <h1 className="text-black text-4xl">{t("translations:admin:welcome")}</h1>
            </div>
            <p>{t("translations:admin:logged_in_as")}: {keycloak.idTokenParsed?.preferred_username}</p>
            <Box
                marginBottom="2rem"
                border={`2px solid grey`}
                borderRadius="5px"
            >
                <Stack
                    sx={{ padding: "1rem", alignItems: "center"}}
                    direction="row"
                >
                    <Stack direction="column" sx={{width: '75%'}}>
                        <TextField
                            helperText={t("translations:insert_person_name_delete")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:delete_person")}
                            required={true}
                            value={personNameToDelete}
                            onChange={(event) =>
                                setPersonNameToDelete(event.target.value)
                            }
                        />
                    </Stack>
                    <Box>
                        <Button
                            primary
                            size="large"
                            sx={{ marginRight: "1rem" }}
                            text={t("translations:delete_person")}
                            onClick={() => deletePerson(personNameToDelete)}
                            startIcon={<Delete />}
                        />
                    </Box>
                </Stack>
            </Box>
            <Box
                marginBottom="2rem"
                border={`2px solid grey`}
                borderRadius="5px"
            >
                <Stack
                    sx={{ padding: "1rem", alignItems: "center" }}
                    direction="row"
                >
                    <Stack direction="row" sx={{ width: '75%' }}>
                        <TextField
                            helperText={t("translations:insert_person_name")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:name")}
                            required={true}
                            value={newName}
                            onChange={(event) =>
                                setNewName(event.target.value)
                            }
                        />
                        <TextField
                            helperText={t("translations:insert_person_last_name")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:last_name")}
                            required={true}
                            value={newLastName}
                            onChange={(event) =>
                                setNewLastName(event.target.value)
                            }
                        />
                        <TextField
                            helperText={t("translations:insert_credit")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:credit")}
                            required={true}
                            value={newCredit}
                            onChange={(event) =>
                                setNewCredit(event.target.value)
                            }
                        />
                    </Stack>
                    <Box>
                        <Button
                            size="large"
                            text="Add Person"
                            onClick={addPersonAction}
                            startIcon={<Add />}
                        />
                    </Box>
                </Stack>
            </Box>
            <Box
                marginBottom="2rem"
                border={`2px solid grey`}
                borderRadius="5px"
            >
                <Stack
                    sx={{ padding: "1rem", alignItems: "center" }}
                    direction="row"
                >
                    <Stack direction="row" sx={{ width: '75%' }}>
                    <FormControl fullWidth sx={{ mt: 2, marginRight: "1rem" }}>
                      <InputLabel id="persons">{t("translations:affected_person")}</InputLabel>
                      <Select
                        labelId="affected_person_select_id"
                        id="affected_person_select"
                        value={affectedPerson}
                        label={t("translations:affected_person")}
                        onChange={(event) => {setAffectedPerson(event.target.value)}}
                      >
                          <MenuItem value={""}>-</MenuItem>
                          {names.map((name) => (
                              <MenuItem value={name}>{name}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                        <TextField
                            helperText={t("translations:insert_old_credit")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:old_credit")}
                            required={true}
                            value={oldCredit}
                            onChange={(event) => {
                                setOldCredit(event.target.value)
                                }
                            }
                        />
                        <TextField
                            helperText={t("translations:insert_new_credit")}
                            sx={{ mt: 2, marginRight: "1rem" }}
                            label={t("translations:new_credit")}
                            required={true}
                            value={newCredit}
                            onChange={(event) =>
                                setNewCredit(event.target.value)
                            }
                        />
                        </Stack>
                        <Box>
                            <Button
                               size="large"
                               text="Set credit"
                               onClick={setCreditAction}
                               startIcon={<KeyboardArrowRight />}
                            />
                        </Box>
                </Stack>
            </Box>
        </PrivateRoute.Admin>
    );
}

export default AdminPage;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type NavigationState = {
    selectedItemId: string
}

const initialState: NavigationState = {
    selectedItemId: "home"
}

export const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        selectItem: (state, action: PayloadAction<string>) => {
            state.selectedItemId = action.payload
        }
    }
})

export const { selectItem } = navigationSlice.actions

export const selectSelectedItem = (state: RootState) =>
    state.navigation.selectedItemId

export default navigationSlice.reducer
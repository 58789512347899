import React from "react";
import {MenuList, Stack} from "@mui/material";
import {Item} from "./navigation.types";
import {useNavigate} from "react-router-dom";
import {NavigationItem} from "./NavigationItem";

type Props = {
    menuItems: Item[]
    selectedItemId: string
    selectItem(id: string): void
}

export const NavigationMenu = ({menuItems, selectedItemId, selectItem}: Props) => {
    const navigate = useNavigate()

    const toNavigationItem = (item: Item) => (
        <NavigationItem
            key={item.id}
            label={item.label}
            linkTo={item.linkTo}
            icon={item.icon}
            selected={selectedItemId === item.id}
            select={() => {
                selectItem(item.id)
                navigate(item.linkTo)
            }}
        />
    )

    return (
        <MenuList sx={{ height: "100%" }}>
            <Stack justifyContent="space-between" height="100%">
                <div>{menuItems.map(toNavigationItem)}</div>
            </Stack>
        </MenuList>
    )
}

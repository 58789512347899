import React from "react";
import {NavigationMenu} from "./NavigationMenu";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectItem, selectSelectedItem} from "../../app/navigationSlice";
import {Item} from "./navigation.types";
import {Home, Payment, TravelExplore, WheelchairPickup, WrapText} from "@mui/icons-material";
import {NavigationHeader} from "./NavigationHeader";

const menuItems: Item[] = [
    {
        id: "/",
        label: "translations:navigation:home",
        linkTo: "/",
        icon: <Home/>
    },
    {
        id: "admin",
        label: "translations:navigation:admin",
        linkTo: "adminTable",
        icon: <WheelchairPickup/>
    },
    {
        id: "log",
        label: "translations:navigation:logs",
        linkTo: "logs",
        icon: <WrapText/>
    },
    {
        id: "paypal",
        label: "translations:navigation:paypal",
        linkTo: "paypal",
        icon: <Payment/>
    }
]

export const Navigation = () => {
    const dispatch = useAppDispatch()
    const selectedItemId = useAppSelector(selectSelectedItem)

    return (
        <>
            <NavigationHeader/>
            <NavigationMenu
                menuItems={menuItems}
                selectedItemId={selectedItemId}
                selectItem={(id) => dispatch(selectItem(id))}
            />
        </>
    );
};

export default Navigation;
export const theme = {
    palette: {
        primary: {
            main: "#CC0033",
            light: "#EB99AD",
            lightest: "#FFDEDE",
            gradient:
                "radial-gradient(81.5% 243.37% at 5.08% 13%, #D50035 0%, #BC002F 100%)"
        },
        secondary: {
            main: "#00AFD7",
            light: "#84DCF0",
            lightest: "#CEF2FA"
        },
        success: {
            main: "#82BA00",
            light: "#B1D958",
            lightest: "#E6F4C6"
        },
        warning: {
            main: "#F39C12",
            light: "#F8C576",
            lightest: "#FFF6CE"
        },
        error: {
            main: "#971B2F",
            light: "#D56072",
            lightest: "#F9DEE5"
        }
    }
}


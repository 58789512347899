import {Outlet} from "react-router-dom";
import {Button, Grid, Stack, StackProps, styled} from "@mui/material";
import Navigation from "./features/navigation/Navigation";
import {useKeycloak} from "@react-keycloak/web";

export const Layout = () => {
    let {keycloak} = useKeycloak()
    const StyledStack = styled(Stack)<StackProps>(({theme}) => ({
        height: "100vh",
        borderRight: `1px solid ${theme.palette.divider}`
    }))
    return (
        <>
            <Grid container spacing={0} height="100vh">
                <Grid item xs={2}>
                    <StyledStack spacing={5} sx={{
                        position: "fixed",
                        width: "16.7%"
                    }}>
                        <Navigation/>
                        {!keycloak.authenticated &&
                            <Button sx={{margin: "0.5rem", backgroundColor: "whitesmoke"}}
                                    onClick={() => keycloak.login()}>{"Login"}</Button>}
                        {keycloak.authenticated &&
                            <Button sx={{margin: "0.5rem", backgroundColor: "whitesmoke"}}
                                    onClick={() => keycloak.logout()}>{"Logout"}</Button>}
                    </StyledStack>
                </Grid>
                <Grid item xs={10}>
                    <StyledStack spacing={1} sx={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <Outlet/>
                    </StyledStack>
                </Grid>
            </Grid>
        </>
    )
}
import logo from "../../assets/logo.svg";
import {Counter} from "./counter/Counter";
import React from "react";

export const DemoPage = () => {
    return (
        <>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <Counter/>
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
            </header>
        </>
    )
}
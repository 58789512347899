import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/demo/counter/counterSlice';
import navigationReducer from "./navigationSlice";
import {personsAPI} from "../services/personsAPI";
import {transactionsAPI} from "../services/transactionsAPI";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    navigation: navigationReducer,
    [personsAPI.reducerPath]: personsAPI.reducer,
    [transactionsAPI.reducerPath]: transactionsAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
          .concat(personsAPI.middleware)
          .concat(transactionsAPI.middleware)
})


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { createBrowserRouter } from "react-router-dom";
import { Layout } from "../Layout";
import HomePage from "../features/home/HomePage";
import AdminPage from "../features/admin/AdminPage";
import LoggingPage from "../features/logging/LoggingPage";
import { DemoPage } from "../features/demo/DemoPage";
import {PayPalPage} from "../features/PayPal";

export const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                { index: true, element: <HomePage /> },
                { path: "/adminTable", element: <AdminPage /> },
                { path: "/logs", element: <LoggingPage /> },
                { path: "/demo", element: <DemoPage /> },
                { path: "/paypal", element: <PayPalPage /> }
            ]
        }
])
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import './index.css';
import "./i18n"
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from './keycloak';

const container = document.getElementById('root')!;

const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ReactKeycloakProvider authClient={keycloak}>
            <App/>
        </ReactKeycloakProvider>
    </Provider>
);

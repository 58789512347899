import { createApi } from "@reduxjs/toolkit/query/react"

import { createBaseQuery } from "./baseAPI"
import {Person, Persons, SetCreditOptions} from "../common/common.types";

export const personsAPI = createApi({
    reducerPath: "personsAPI",
    baseQuery: createBaseQuery("/service/person"),
    tagTypes: ["persons", "transactions"],
    endpoints: (builder) => ({
        getPersons: builder.query<Persons, any>({
            query: (searchOptions) => ({
                method: "GET",
                url: "all",
                body: JSON.stringify(searchOptions),
                headers: { "Content-Type": "application/json" },
                // maxContentLength: Infinity, // maximale Größe der Antwort in Byte
                // maxBodyLength: Infinity, // maximale Größe des Anfragebodys in Byte
                // timeout: 3600000, // Zeitlimit in Millisekunden
            }),
            providesTags: ["persons"]
        }),
        addPerson: builder.mutation<string, Person>({
            query: (person) => ({
                method: "POST",
                url: `add?name=${person.name}&lastName=${person.lastName}&credit=${person.credit}`,
                body: "",
                headers: { "Content-Type": "application/json" },
            }),
            invalidatesTags: ["persons"]
        }),
        deletePerson: builder.mutation<string, string>({
            query: (name) => ({
                method: "POST",
                url: `deleteByName?name=${name}`,
                body: "",
                headers: { "Content-Type": "application/json" },
            }),
            invalidatesTags: ["persons"]
        }),
        setCredit: builder.mutation<string, SetCreditOptions>({
            query: (setCreditOptions) => ({
                method: "POST",
                url: `setCredit?affectedPerson=${setCreditOptions.affectedPerson}&username=${setCreditOptions.triggeredFromUser}&oldCredit=${setCreditOptions.oldCredit}&newCredit=${setCreditOptions.newCredit}`,
                body: "",
                headers: { "Content-Type": "application/json" },
            }),
            transformResponse: (rawResult: string) => {
                return rawResult
            },
            invalidatesTags: ['persons', 'transactions'],
        })
    })
})

export const {
    useGetPersonsQuery,
    useAddPersonMutation,
    useDeletePersonMutation,
    useSetCreditMutation
} = personsAPI

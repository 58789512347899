import React from "react";

export const PayPalPage = () => {
    return (
        <>
            <header className="App-header">
                <h2><a href={"https://www.paypal.de"} >paypal.de</a></h2>
            </header>
        </>
    )
}
export const theme = {
    palette: {
        text: {
            primary: "#1D2A39", // black
            secondary: "#8A9096", // grayDark
            disabled: "#E6E8EB" // grayLight
        }
    },
    common: {
        black: "#1D2A39"
    },
    general: {
        black: "#1D2A39",
        white: "#FFFFFF",
        grayDarkest: "#50575C",
        grayDark: "#8A9096",
        gray: "#CACDD1",
        grayLight: "#E6E8EB",
        grayLightest: "#F6F7F8"
    },
}
import { Container, Stack, styled } from "@mui/material"
import React from "react"
// @ts-ignore
import logo from "../../assets/images/TSVLogo.png"

const StyledImage = styled("img")(() => ({
    alignSelf: "center",
    maxWidth: "10.875rem",
    height: "100%",
    width: "100%",
    margin: 'auto'
}))

export const NavigationHeader = () => {
    return (
        <Container sx={{ marginTop: "1.25rem", marginBottom: "0.5rem" }}>
            <Stack sx={{margin: "auto"}}>
                <StyledImage src={logo}/>
            </Stack>
        </Container>
    )
}

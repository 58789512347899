import React from 'react';
import { useTranslation } from "react-i18next";
import { useGetPersonsQuery } from "../../services/personsAPI";
import PersonTable from "./PersonsTable";


const HomePage = () => {
    const { t } = useTranslation()
    let {data} = useGetPersonsQuery();
    if (!data) return <></>
//    console.log("new date", data)
    return (
        <div>
            <h1 className="text-green-800 text-4xl">{t("translations:title")}</h1>
            <div>
                <PersonTable data={data}/>
            </div>
        </div>
    );
};

export default HomePage;
export const theme = {
    palette: {
        primary: {
            main: "#1976d2"
        },
        secondary: {
            main: "#9c27b0"
        }
    }
}

import { createTheme, ThemeOptions } from "@mui/material"
import React from "react"

import { theme as defaultTheme } from "./defaultTheme"
import { theme as tsvTheme } from "./tsvTheme"
import { theme as otherTheme } from "./otherTheme"


declare module "@mui/material/styles" {
    interface ThemeOptions {
        general?: {
            black: React.CSSProperties["color"]
            white: React.CSSProperties["color"]
            grayDarkest: React.CSSProperties["color"]
            grayDark: React.CSSProperties["color"]
            gray: React.CSSProperties["color"]
            grayLight: React.CSSProperties["color"]
            grayLightest: React.CSSProperties["color"]
        }
    }

    interface Theme {
        general: {
            black: React.CSSProperties["color"]
            white: React.CSSProperties["color"]
            grayDarkest: React.CSSProperties["color"]
            grayDark: React.CSSProperties["color"]
            gray: React.CSSProperties["color"]
            grayLight: React.CSSProperties["color"]
            grayLightest: React.CSSProperties["color"]
        }
    }

    interface PaletteColor {
        lightest?: string
        gradient?: string
    }
}

export type ThemeNames =
    // | "red"
    | "TSV"
    | "OTHER"

type AvailableThemes = {
    [key in ThemeNames]: ThemeOptions
}

const themeOptions: AvailableThemes = {
    // red: sdbgTheme,
    TSV: tsvTheme,
    OTHER: otherTheme
}

/**
 * Loads the theme with the given <code>themeId</code>
 * @param themeId id of theme
 */
export const loadTheme = (themeId: ThemeNames) => {
    return createTheme({ ...defaultTheme, ...themeOptions[themeId] })
}

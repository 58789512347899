import React from 'react';
import { useGetTransactionsQuery } from "../../services/transactionsAPI";
import EnhancedTable from "./LoggingTable";


export const HomePage = () => {
//{JSON.stringify(data)}
//
    const {data} = useGetTransactionsQuery();
    if (!data) return <></>
    return (
        <div>
            <h1 className="text-green-800 text-4xl">Welcome to the Logging Page</h1>
            <EnhancedTable data={data} />
        </div>
    );
};

export default HomePage;
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { Person, Persons } from "../../common/common.types";
import { useTranslation } from "react-i18next"
import { Button } from "../../common/Button";
import {Add, Check, Remove} from "@mui/icons-material";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useSetCreditMutation } from "../../services/personsAPI";
import { useKeycloak } from "@react-keycloak/web";

interface Data {
    id: string,
    name: string,
    lastName: string,
    credit: number
}

function createData(
    id: string,
    name: string,
    lastName: string,
    credit: number
): Data {
    return { id, name, lastName, credit };
}

const getRows = (data: Person[]) => {
    return data.map( person => {
//         console.log("neuer wert", person.credit)
        return createData(person.id, person.name, person.lastName, person.credit)
    })
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'translations:name',
    },
    {
        id: 'lastName',
        numeric: false,
        disablePadding: false,
        label: 'translations:last_name',
    },
    {
        id: 'credit',
        numeric: true,
        disablePadding: false,
        label: 'translations:credit',
    }
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'name';
const DEFAULT_ROWS_PER_PAGE = 15;

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, newOrderBy);
        };
    return (
        <TableHead >
            <TableRow sx={{backgroundColor: theme.palette.primary.lightest}}>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ fontSize: "1rem", fontWeight: "bold" }}
                        sx={{ paddingLeft: "16px"}}
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell />
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar() {

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 }
            }}
        >
        </Toolbar>
    );
}

type SetCreditOptions = {
    affectedPerson: string,
    triggeredFromUser: string,
    oldCredit: number,
    newCredit: number
}

export default function PersonTable(data: Persons) {
    const {t} = useTranslation()
    const {keycloak} = useKeycloak();
    const rows = getRows(data.data)
    const [setCredit] = useSetCreditMutation()

    // const isAdmin: boolean = keycloak.idTokenParsed?.role == "tsvAdmin"
    const isAdmin: boolean = keycloak.idTokenParsed?.preferred_username.toLowerCase() == "stefan" ||
    keycloak.idTokenParsed?.preferred_username.toLowerCase() == "timo" ||
    keycloak.idTokenParsed?.preferred_username.toLowerCase() == "moritz"
//     console.log("keycloak.idTokenParsed", keycloak.idTokenParsed)

    const [counters, setCounters] = useState(rows.map(() => 0));

    const setNewCreditValue = (name: string, credit: number, val: number) => {
        const creditOptions: SetCreditOptions = {
            affectedPerson: name,
            triggeredFromUser: keycloak.idTokenParsed?.preferred_username,
            oldCredit: credit,
            newCredit: credit + val
        };
        setCredit(creditOptions)
    }

    function handleIncrement(index: number) {
        const newCounters = [...counters];
        newCounters[index] += 1;
        setCounters(newCounters);
    }

    function handleDecrement(index: number) {
        const newCounters = [...counters];
        newCounters[index] -= 1;
        setCounters(newCounters);
    }


    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<keyof Data>(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState<Data[] | null>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);

    React.useEffect(() => {
        let rowsOnMount = stableSort(rows, getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),);
        rowsOnMount = rowsOnMount.slice(0, DEFAULT_ROWS_PER_PAGE);
        // @ts-ignore
        setVisibleRows(rowsOnMount);
    }, []);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            // @ts-ignore
            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = React.useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );
            // @ts-ignore
            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, dense, rowsPerPage],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);

            setPage(0);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(0, updatedRowsPerPage);
            // @ts-ignore
            setVisibleRows(updatedRows);

            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [order, orderBy],
    );

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, whiteSpace: 'nowrap' }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.name}
                                        >
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.lastName}</TableCell>
                                            <TableCell align="left">
                                                {row.credit} { }
                                                {counters[index] > 0 && "+"}
                                                {counters[index] != 0 && counters[index]}
                                            </TableCell>
                                            <TableCell align="left">{(isAdmin || keycloak.idTokenParsed?.preferred_username.toLowerCase() == row.name.toLowerCase()) && <Button onClick={() => handleDecrement(index)} startIcon={<Remove sx={{color: "red"}} />} />}</TableCell>
                                            <TableCell align="left">{(isAdmin || (keycloak.idTokenParsed?.preferred_username.toLowerCase() == row.name.toLowerCase())) && <Button disabled={!isAdmin && counters[index] >= 0} onClick={() => handleIncrement(index)} startIcon={<Add sx={{color: "green"}}/>}  />}</TableCell>
                                            <TableCell align="left">{(isAdmin || keycloak.idTokenParsed?.preferred_username.toLowerCase() == row.name.toLowerCase()) && <Button secondary disabled={ counters[index] == 0} startIcon={<Check />} onClick={() => setNewCreditValue(row.name, row.credit, counters[index])} />}</TableCell>
                                        </TableRow>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={t("translations:dense_padding")}
            />
        </Box>
    );
}

import { createApi } from "@reduxjs/toolkit/query/react"

import { createBaseQuery } from "./baseAPI"
import {Transactions} from "../common/common.types";

export const transactionsAPI = createApi({
    reducerPath: "transactions",
    baseQuery: createBaseQuery("/service/transaction"),
    tagTypes: ["transactions"],
    endpoints: (builder) => ({
        getTransactions: builder.query<Transactions, any>({
            query: (searchOptions) => ({
                method: "GET",
                url: "all",
                body: JSON.stringify(searchOptions),
                headers: { "Content-Type": "application/json" },
            }),
            providesTags: ["transactions"]
        })
    })
})

export const {
    useGetTransactionsQuery
} = transactionsAPI
